import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {observer} from 'mobx-react'
import {VoidFunction} from '../../../../Types'
import {useIntl} from 'react-intl'

const RightPanelDefault: FC<{
  show: boolean
  title: string
  close: VoidFunction
  left_buttons: any
  right_buttons: any
}> = observer((props) => {
  const intl = useIntl()
  let classNames = ['bg-white drawer drawer-end w-50']

  if (props.show) {
    classNames.push('drawer-on')
  }

  const renderButtons = (buttons: any) => {
    const html = []
    for (let i in buttons) {
      html.push(buttons[i])
    }

    return html
  }

  return (
    <div
      className={`${classNames.join(' ')} border-start`}
      data-kt-drawer-overlay='true'
      data-kt-drawer='true'
      data-kt-swapper-mode='prepend'
      data-kt-drawer-activate='true'
      data-kt-drawer-width="{default:'400px', 'lg': '40%'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-close='#kt_activities_close'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header w-100 px-0 position-relative'>
          <div
            className='position-fixed w-100 d-inline-flex z-index-1 position-relative bg-white'
            style={{padding: '1rem 2.25rem'}}
          >
            <h3 className='card-title fw-bolder text-dark'>{props.title}</h3>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                // id='kt_activities_close'
                onClick={() => props.close()}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </button>
            </div>
          </div>
        </div>
        <div className='card-body position-relative' style={{background: '#F5F8FA'}}>
          {props.children}
        </div>
        <div className='card-footer py-5 justify-content-between w-100 d-inline-flex'>
          <div>{renderButtons(props.left_buttons)}</div>

          <div>{renderButtons(props.right_buttons)}</div>
        </div>
      </div>
    </div>
  )
})

export {RightPanelDefault}
