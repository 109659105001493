import React, {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'

export function IsFree() {
  const {pathname} = useLocation()

  document.addEventListener('DOMContentLoaded', function () {})
  useEffect(() => {
    const freeAreaWrapper: any = document.querySelector('.free-area-wrapper')
    const head: any = document.getElementById('kt_header')
    const toolbar: any = document.getElementById('kt_toolbar')

    function updateStickyTop() {
      if (toolbar && freeAreaWrapper) {
        const height = toolbar.offsetHeight + head.offsetHeight
        freeAreaWrapper.style.top = `${height}px`
      }
    }

    updateStickyTop()

    window.addEventListener('resize', updateStickyTop)

    const observer = new MutationObserver(updateStickyTop)
    observer.observe(toolbar, {attributes: true, childList: true, subtree: true})
  }, [pathname])

  return (
    <div className={'free-area-wrapper pb-5'}>
      <div className={'bg-light-danger p-3'}>
        <div className={'ps-7'}>
          Využíváte bezplatnou verzi SVJ Aplikace. Pokud chcete odemknout všechny funkce aplikace,
          kontaktujte nás na: obchod@svjaplikace.cz.
        </div>
      </div>
    </div>
  )
}
