import {REPORT_VISIBILITY_TYPE_MAP} from '../../Constants/Reports'

export const getLabelTranslation = (type: string) => {
  switch (type) {
    case 'green':
      return 'CALENDAR.EVENT_TYPE.GREEN'
    case 'red':
      return 'CALENDAR.EVENT_TYPE.RED'
    case 'yellow':
      return 'CALENDAR.EVENT_TYPE.YELLOW'
    case 'blue':
      return 'CALENDAR.EVENT_TYPE.BLUE'
    case 'black':
      return 'CALENDAR.EVENT_TYPE.BLACK'
    default:
      return 'CALENDAR.EVENT_TYPE.DEFAULT'
  }
}

export const getColorLabel = (type: string) => {
  switch (type) {
    case 'green':
      return 'success'
    case 'red':
      return 'danger'
    case 'yellow':
      return 'warning'
    case 'blue':
      return 'primary'
    case 'black':
      return 'dark'
    default:
      return 'light'
  }
}

export const getNotifyPeriodOptions = () => {
  return [
    {
      value: 'none',
      translate: true,
      label: 'CALENDAR.FORM.NO_REMIND',
    },
    {
      value: '1_h',
      translate: true,
      label: 'CALENDAR.FORM.HOUR_BEFORE_REMIND',
    },
    {
      value: '1_d',
      translate: true,
      label: 'CALENDAR.FORM.DAY_BEFORE_REMIND',
    },
  ]
}

export const filterEvents = (events: any[], year: number, month: number) => {
  return events.filter((event: any) => {
    const startDate = new Date(event.start)
    const endDate = new Date(event.end)

    return (
      (startDate.getFullYear() === year && startDate.getMonth() + 1 === month) ||
      (endDate.getFullYear() === year && endDate.getMonth() + 1 === month) ||
      (startDate <= new Date(year, month - 1, 1) && endDate >= new Date(year, month, 0))
    )
  })
}
